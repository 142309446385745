import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Combination } from 'src/app/modules/shared/interfaces/combination/combination.interface';
import {
  CombinationFilter,
  CombinationFilters
} from 'src/app/modules/shared/interfaces/combination/combination-filters/combination-filters.interface';

export const userCombinations = createActionGroup({
  source: '[ USER COMBINATIONS ]',
  events: {
    'load table data and filters': props<{ options: CombinationFilter }>(),
    'load table and data filters success': props<{
      combinations: Array<Combination>;
      filters: CombinationFilters;
    }>(),
    'clean up table data': emptyProps
  }
});
