import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CUSTOM_CONTROLS } from '../../../constants/custom-controls/custom-controls.constants';
import { CombinationFilter } from '../../../interfaces/combination/combination-filters/combination-filters.interface';

@Component({
  selector: 'app-autocomplete-filter-control',
  templateUrl: 'autocomplete-filter-control.component.html',
  styleUrls: ['autocomplete-filter-control.component.sass'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AutocompleteFilterControlComponent implements OnInit, OnDestroy {
  @Input()
  set values(data: Array<string>) {
    if (data) {
      if (data.length === 1 && this.customControlName === CUSTOM_CONTROLS.DRUG_CATEGORY) {
        this.selectedFilters.drugCategory = data[0];
        this.parent.form.controls[this.customControlName].setValue(data[0]);
      }
      this.initialValues = data;
      this.filteredValues = data.map((x) => x);
    }
  }

  // Placeholder text
  @Input() placeholder!: string;

  // Name of the control passed from the parent
  @Input() customControlName!: string;

  @Input() selectedFilters!: CombinationFilter;

  // EventEmitter to emit updated inputTerm
  @Output() inputTerm = new EventEmitter<string>();

  @Output() selectedOption = new EventEmitter<string>();

  // Subject to handle input term changes
  inputTermChanged = new Subject<string>();

  initialValues: Array<string> = [];

  filteredValues!: Array<string>;

  // Current input value
  currentValue!: string;

  private onDestroy = new Subject<void>();

  constructor(private parent: FormGroupDirective) {}

  ngOnInit() {
    this.subscribeTotermChange();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  optionSelected(value: string) {
    this.currentValue = value;
    this.selectedOption.emit(value);
    // Validation is called before mat-option select event
    this.parent.form.controls[this.customControlName].setValue(value);
  }

  onInputValueChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.inputTermChanged.next(inputElement.value);
  }

  private subscribeTotermChange() {
    this.inputTermChanged.pipe(takeUntil(this.onDestroy)).subscribe((inputTerm: string) => {
      this.currentValue = inputTerm;
      this.inputTerm.emit(this.currentValue);
      this.filteredValues = this.initialValues.filter((x) =>
        x.toLocaleLowerCase().includes(inputTerm.toLocaleLowerCase())
      );
    });
  }
}
