import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ROUTES } from '@shared/constants/routes/routes.constants';

import { UserRoleComponent } from './components/user-role/user-role.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: '', component: UserRoleComponent, pathMatch: 'full' },
  {
    path: ROUTES.COMBINATIONS,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: ROUTES.GUIDE,
    loadChildren: () =>
      import('./modules/instructions/instructions.module').then((m) => m.InstructionsModule)
  },
  {
    path: ROUTES.ABBREVIATIONS,
    loadChildren: () =>
      import('./modules/abbreviations/abbreviations.module').then((m) => m.AbbreviationsModule)
  },
  {
    path: ROUTES.ADMINISTRATION,
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: ROUTES.NOT_FOUND,
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then((m) => m.ErrorPageModule)
  },
  {
    path: ROUTES.SERVER_ERROR,
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then((m) => m.ErrorPageModule)
  },
  {
    path: ROUTES.FORBIDDEN,
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then((m) => m.ErrorPageModule)
  },
  {
    path: ROUTES.LEGAL,
    loadChildren: () => import('./modules/legal/legal.module').then((m) => m.LegalModule)
  },
  {
    path: '**',
    redirectTo: ROUTES.NOT_FOUND
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
