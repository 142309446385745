import { ROUTES } from '@shared/constants/routes/routes.constants';
import {
  BACKEND_URL,
  MSAL_API_CONFIG_BACKEND_SCOPES,
  MSAL_API_CONFIG_CHECK_SCOPES,
  MSAL_API_CONFIG_CHECK_URI,
  MSAL_API_CONFIG_GPR_SCOPES,
  MSAL_API_CONFIG_GPR_URI,
  MSAL_AUTH_AUTHORITY,
  MSAL_DEV_API_CONFIG_BACKEND_URI,
  MSAL_KNOWN_AUTHORITIES,
  PROFILE_DEV_URL
} from '@shared/constants/url/url.constants';

export const environment = {
  develop: true,
  environment: 'DEV',
  production: false,
  enableProdMode: true,
  backendUrl: BACKEND_URL,
  msalConfig: {
    auth: {
      clientId: '677d87d3-bdb1-4add-b608-22c3745ee64c',
      authority: MSAL_AUTH_AUTHORITY,
      knownAuthorities: MSAL_KNOWN_AUTHORITIES,
      redirectUri: window.location.origin + '/' + ROUTES.REDIRECT_URI,
      postLogoutRedirectUri: window.location.origin
    },
    loginFailedRoute: ROUTES.FORBIDDEN
  },
  apiConfig: {
    gpr: {
      scopes: MSAL_API_CONFIG_GPR_SCOPES,
      uri: MSAL_API_CONFIG_GPR_URI
    },
    check: {
      scopes: MSAL_API_CONFIG_CHECK_SCOPES,
      uri: MSAL_API_CONFIG_CHECK_URI
    },
    customBackend: {
      scopes: MSAL_API_CONFIG_BACKEND_SCOPES,
      uri: MSAL_DEV_API_CONFIG_BACKEND_URI
    }
  },
  // instana config
  trackingUi: {
    enable: true,
    reportingUrl: '',
    apiKey: ''
  },
  profile: PROFILE_DEV_URL
};
