import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ROUTES } from '../constants/routes/routes.constants';
import { DISABLE_POSITION_FIXED_WIDTH_IN_PX } from '../constants/utils/utils.constants';

@Directive({
  selector: '[appScrollToFixed]'
})
export class ScrollToFixedDirective {
  // Represents the value for how many pixels should the user scrolled to make the element fixed.
  @Input() pixels: number | undefined;

  private doc: Document;
  private applyClass = false;

  constructor(
    @Inject(DOCUMENT) doc: Document,
    private element: ElementRef,
    private router: Router
  ) {
    this.doc = doc;
  }

  @HostListener('window:scroll', [])

  /**
   * Runs on each user scroll. If the scroll offset from the top is
   * bigger than the passed pixels we should fix the element.
   */
  /* tslint:disable */
  private onWindowScroll(): void {
    const scroll = this.doc.documentElement.scrollTop;

    if (
      !this.pixels ||
      isNaN(this.pixels) ||
      window.innerWidth < DISABLE_POSITION_FIXED_WIDTH_IN_PX
    ) {
      return;
    }

    if (!this.applyClass && scroll > this.pixels) {
      // current workaround for positioning because we can't use position sticky for element inside mat-tab
      if (
        this.router.url === '/' + ROUTES.COMBINATIONS ||
        this.router.url === '/' + ROUTES.ABBREVIATIONS
      ) {
        this.element.nativeElement.classList.add('small-header-table-top');
      } else if (this.router.url === '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.ENTITIES) {
        this.element.nativeElement.classList.add('entities-table-top');
      } else if (this.router.url === '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.COMBINATIONS) {
        this.element.nativeElement.classList.add('small-header-table-top');
      }

      this.element.nativeElement.classList.add('fixed-directive');
      this.applyClass = true;
    } else if (this.applyClass && scroll < this.pixels) {
      if (this.router.url === '/' + ROUTES.COMBINATIONS) {
        this.element.nativeElement.classList.remove('small-header-table-top');
      } else if (this.router.url === '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.ENTITIES) {
        this.element.nativeElement.classList.remove('entities-table-top');
      } else if (this.router.url === '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.COMBINATIONS) {
        this.element.nativeElement.classList.remove('small-header-table-top');
      }
      this.element.nativeElement.classList.remove('fixed-directive');
      this.applyClass = false;
    }
  }
}
