// Http statuses used in the application
// https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html
export const HTTP_STATUS_CODE = {
  // Informational 1xx

  // Successful 2xx
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,

  // Redirection 3xx
  FOUND: 302,

  // Client Error 4xx
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  PRECONDITION_FAILED: 412,

  // Server Error 5xx
  INTERNAL_SERVER_ERROR: 500,
  BAG_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503
};
