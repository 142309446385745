import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-small-header',
  templateUrl: './small-header.component.html',
  styleUrls: ['./small-header.component.sass']
})
export class SmallHeaderComponent {
  @Input() back: boolean | undefined;

  @Input() loading: boolean | undefined | null;

  environment = environment;

  constructor(private readonly location: Location) {}

  goBack() {
    this.location.back();
  }
}
