import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HomeService } from 'src/app/modules/shared/service/home/home.service';

import { CombinationFilters } from '../interfaces/combination/combination-filters/combination-filters.interface';

@Injectable()
export class FiltersResolver {
  constructor(private homeService: HomeService) {}

  resolve(): Observable<CombinationFilters> {
    return this.homeService.getFilters();
  }
}
