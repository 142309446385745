import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ROUTES } from '../modules/shared/constants/routes/routes.constants';
import { User } from '../modules/shared/interfaces/user/user.interface';
import { UserService } from '../modules/shared/service/user/user.service';

@Injectable()
export class AdminGuard {
  constructor(private router: Router, private userService: UserService) {}

  async canActivate() {
    return this.userService
      .getUser()
      .toPromise()
      .then((res: User | undefined) => {
        if (res?.admin) {
          return true;
        }
        this.router.navigateByUrl('/' + ROUTES.FORBIDDEN);
        return false;
      })
      .catch((err) => {
        this.router.navigateByUrl('/' + ROUTES.HOME);
        return false;
      });
  }
}
