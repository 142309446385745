import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-header',
  templateUrl: './item-header.component.html',
  styleUrls: ['./item-header.component.sass']
})
export class ItemHeaderComponent {
  @Input() title: string | undefined;
  @Input() loading: boolean | undefined;
  @Input() goBackURL: string | undefined;

  environment = environment;

  constructor(private readonly location: Location, private readonly router: Router) {}

  goBack() {
    this.goBackURL ? this.router.navigateByUrl(this.goBackURL) : this.location.back();
  }
}
