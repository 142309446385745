import { Component } from '@angular/core';

import { ROUTES } from '../../constants/routes/routes.constants';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.sass']
})
export class AdminFooterComponent {
  ROUTES = ROUTES;
}
