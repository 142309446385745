import { Component } from '@angular/core';

import { UserFacade } from '../../../../store/user/user.facade';
import { ROUTES } from '../../constants/routes/routes.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent {
  ROUTES = ROUTES;

  constructor(public readonly userFacade: UserFacade) {}
}
