import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';

import { TEXT } from '../../../constants/text/text.constants';
import {
  CombinationFilter,
  CombinationFilters
} from '../../../interfaces/combination/combination-filters/combination-filters.interface';
import { AutocompleteFilterComponent } from '../autocomplete-filter/autocomplete-filter.component';

@Component({
  selector: 'app-autocomplete-filters',
  templateUrl: 'autocomplete-filters.component.html',
  styleUrls: ['autocomplete-filters.component.sass']
})
export class AutocompleteFiltersComponent implements OnInit {
  TEXT = TEXT;

  @ViewChildren(AutocompleteFilterComponent)
  autocompleteComponents!: QueryList<AutocompleteFilterComponent>;

  @Input() filters!: CombinationFilters;

  @Input() loading!: boolean;

  @Input() filter!: CombinationFilter;

  @Output() filterChanged = new EventEmitter<CombinationFilter>();

  // Used for making requests
  private selectedFilters!: CombinationFilter;

  // Used for disabling reset button
  private inputTermFilters!: CombinationFilter;

  ngOnInit() {
    this.selectedFilters = { ...this.filter };
    this.inputTermFilters = { ...this.filter };
  }

  resetFilters() {
    if (
      !this.inputTermFilters.drugCategory &&
      !this.inputTermFilters.drug &&
      !this.inputTermFilters.carrierSolution &&
      !this.inputTermFilters.product
    ) {
      return;
    }

    this.autocompleteComponents.forEach((component: AutocompleteFilterComponent) =>
      component.clearInput(false)
    );
    this.selectedFilters = {};
    this.inputTermFilters = {};
    this.filterChanged.emit(this.selectedFilters);
  }

  selectedDrugCategory(value: string) {
    this.selectedFilters = { ...this.selectedFilters, drugCategory: value };
    this.inputTermFilters.drugCategory = value;
    this.filterChanged.emit(this.selectedFilters);
  }

  selectedDrugs(value: string) {
    this.selectedFilters = { ...this.selectedFilters, drug: value };
    this.inputTermFilters.drug = value;
    this.filterChanged.emit(this.selectedFilters);
  }

  selectedCarrierSolutions(value: string) {
    this.selectedFilters = { ...this.selectedFilters, carrierSolution: value };
    this.inputTermFilters.carrierSolution = value;
    this.filterChanged.emit(this.selectedFilters);
  }

  selectedProduct(value: string) {
    this.selectedFilters = { ...this.selectedFilters, product: value };
    this.inputTermFilters.product = value;
    this.filterChanged.emit(this.selectedFilters);
  }

  inputTermDrugCategory(value: string) {
    this.inputTermFilters.drugCategory = value;
    if (value !== '') {
      this.selectedFilters.drugCategory = value;
    }
  }

  inputTermDrug(value: string) {
    this.inputTermFilters.drug = value;
    if (value !== '') {
      this.selectedFilters.drug = value;
    }
  }

  inputTermCarrierSolutions(value: string) {
    this.inputTermFilters.carrierSolution = value;
    if (value !== '') {
      this.selectedFilters.carrierSolution = value;
    }
  }

  inputTermProduct(value: string) {
    this.inputTermFilters.product = value;
    if (value !== '') {
      this.selectedFilters.product = value;
    }
  }
}
