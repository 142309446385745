import { HttpParams } from '@angular/common/http';

import { CustomHttpParamEncoderService } from '../../service/custom-http-param-encoder/custom-http-param-encoder';

export class HttpParamsFactory {
  constructor(public options: any) {}

  returnParams(params?: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    }

    if (!this.options) {
      return params;
    }

    if (this.options.drugCategory) {
      params = params.append('drugCategories', this.options.drugCategory);
    }

    if (this.options.drug) {
      params = params.append('drugs', this.options.drug);
    }

    if (this.options.carrierSolution) {
      params = params.append('carrierSolutions', this.options.carrierSolution);
    }

    if (this.options.product) {
      params = params.append('products', this.options.product);
    }

    if (this.options.name) {
      params = params.append('name', this.options.name);
    }

    if (this.options.listAll) {
      params = params.append('listAll', this.options.listAll);
    }

    if (this.options.showNotMaintained) {
      params = params.append('showNotMaintained', this.options.showNotMaintained);
    }

    return params;
  }
}
