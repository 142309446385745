import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';

import { CustomHttpError } from '../../interfaces/custom-http-error/custom-http-error.interface';

@Injectable()
export class HandleResponseService {
  constructor(
    private readonly location: Location,
    private readonly notificationService: CxSnackbarService
  ) {}

  handleSuccess(messageSuccess: string, back = false) {
    this.notificationService.success(messageSuccess);
    if (back) {
      this.location.back();
    }
  }

  handleError(err: CustomHttpError) {
    if (err.error.details) {
      this.notificationService.error(err.error.details);
    }
  }
}
