import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { forkJoin, Observable } from 'rxjs';
import { ROUTES } from 'src/app/modules/shared/constants/routes/routes.constants';
import { HttpParamsFactory } from 'src/app/modules/shared/factory/http-params/http-params.factory';
import {
  CombinationFilter,
  CombinationFilters
} from 'src/app/modules/shared/interfaces/combination/combination-filters/combination-filters.interface';

import { environment } from '../../../../../environments/environment';
import { Article } from '../../interfaces/article/article.interface';
import { Combination } from '../../interfaces/combination/combination.interface';
import {
  CombinationWithVariants,
  CombinationWithVariantsForm
} from '../../interfaces/combination/combination-with-variants/combination-with-variants.interface';

@Injectable()
export class CombinationsService {
  constructor(private readonly httpService: HttpService) {}

  getAdminCombinationsWithFilters(
    options: CombinationFilter
  ): Observable<[Array<Combination>, CombinationFilters]> {
    const paramCombinationsOptions = {
      ...options,
      listAll: true
    };
    const paramFiltersOptions = {
      ...options,
      listAll: true,
      showNotMaintained: true
    };
    const paramsCombinations = new HttpParamsFactory(paramCombinationsOptions).returnParams();
    const paramsFilters = new HttpParamsFactory(paramFiltersOptions).returnParams();
    return forkJoin([
      this.httpService
        .request(ROUTES.COMBINATIONS)
        .params(paramsCombinations)
        .get(environment.backendUrl),
      this.httpService.request(ROUTES.FILTERS).params(paramsFilters).get(environment.backendUrl)
    ]);
  }

  getAdminFilters(options: CombinationFilter, listAll?: boolean): Observable<CombinationFilters> {
    if (listAll) {
      options = {
        drug: options.drug,
        drugCategory: options.drugCategory,
        listAll: true
      };
    }
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService.request(ROUTES.FILTERS).params(params).get(environment.backendUrl);
  }

  headCombination(body: CombinationFilter): Observable<CombinationWithVariants> {
    const params = new HttpParamsFactory(body).returnParams();
    if (body.combinationId) {
      return this.httpService
        .request(ROUTES.COMBINATIONS + `/${body.combinationId}`)
        .params(params)
        .head(environment.backendUrl);
    } else {
      return this.httpService
        .request(ROUTES.COMBINATIONS)
        .params(params)
        .head(environment.backendUrl);
    }
  }

  postCombination(body: CombinationWithVariantsForm): Observable<CombinationWithVariants> {
    return this.httpService.request(ROUTES.COMBINATIONS).body(body).post(environment.backendUrl);
  }

  putCombination(body: CombinationWithVariantsForm): Observable<CombinationWithVariants> {
    return this.httpService
      .request(ROUTES.COMBINATIONS + `/${body.combinationId}`)
      .body(body)
      .put(environment.backendUrl);
  }

  putArticles(body: Array<Article>, id: string): Observable<Array<Article>> {
    const modifiedArticles = body.map(this.escapeUrl);
    const result = this.httpService
      .request(ROUTES.VARIANTS + `/${id}/` + ROUTES.ARTICLES)
      .body(modifiedArticles)
      .put(environment.backendUrl);
    return result;
  }

  escapeUrl(article: Article): Article {
    return {
      ...article,
      url: article.url.replace(/http:\/\//g, 'http:\\/\\/').replace(/https:\/\//g, 'https:\\/\\/')
    };
  }

  deleteCombination(id: string): Observable<Combination> {
    return this.httpService.request(ROUTES.COMBINATIONS + `/${id}`).delete(environment.backendUrl);
  }
}
