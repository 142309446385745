import { createReducer, on } from '@ngrx/store';

import { Combination } from '../../modules/shared/interfaces/combination/combination.interface';
import { CombinationFilters } from '../../modules/shared/interfaces/combination/combination-filters/combination-filters.interface';
import { userCombinations } from './user-combinations.actions';

export const userCombinationsFeatureKey = 'userCombinations';

export interface UserCombinationsState {
  filters: CombinationFilters;
  combinations: Array<Combination>;
  loading: boolean;
}

export const initialState: UserCombinationsState = {
  filters: {},
  combinations: [],
  loading: false
};

export const userCombinationsReducer = createReducer<UserCombinationsState>(
  initialState,
  on(userCombinations.loadTableDataAndFilters, (state: UserCombinationsState) => ({
    ...state,
    loading: true
  })),
  on(userCombinations.cleanUpTableData, (state: UserCombinationsState) => ({
    ...state,
    loading: false
  })),
  on(
    userCombinations.loadTableAndDataFiltersSuccess,
    (
      state: UserCombinationsState,
      data: {
        combinations: Array<Combination>;
        filters: CombinationFilters;
      }
    ) => ({
      ...state,
      filters: data.filters,
      combinations: data.combinations,
      loading: false
    })
  )
);
