import { createSelector } from '@ngrx/store';

import { State } from '../index';
import { UserState } from './user.reducer';

const userState = (state: State) => state.user;

export const getUser = createSelector(userState, (state: UserState) => state.user);
export const getLoading = createSelector(userState, (state: UserState) => state.loading);
export const getCombinationFilter = createSelector(userState, (state: UserState) => state.filter);
