import { DestroyRef, Inject, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  private readonly destroyRef = inject(DestroyRef);

  private readonly oAuthSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  public configureAuth(): Observable<boolean> {
    this.authService.handleRedirectObservable().subscribe({});

    this.authService.instance.enableAccountStorageEvents();

    this.handelAccountAddedOrRemoved();

    this.handleInteraction();

    return this.oAuthSubject.asObservable();
  }

  public logout(): void {
    this.authService.logout({ idTokenHint: this.authService.instance.getActiveAccount()?.idToken });
  }

  private checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  private isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  private handelAccountAddedOrRemoved(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });
  }

  private handleInteraction(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        if (this.isLoggedIn()) {
          this.checkAndSetActiveAccount();
          this.oAuthSubject.next(true);
        } else {
          this.login();
        }
      });
  }
}
