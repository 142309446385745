<cx-header>
  <cx-header-title>
    <span>B.Comp</span>
    <span class="product-description">
      – the B.Braun drug compatibility database for products, materials and IV solutions
    </span>
    <span class="red">{{ environment.environment }}</span>
  </cx-header-title>
  <cx-header-back-navigator
    *ngIf="back"
    (click)="goBack()"
  >
    Back
  </cx-header-back-navigator>
</cx-header>
