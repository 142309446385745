<mat-form-field class="autocomplete-container font-sm">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    #input
    matInput
    class="autocomplete-input font-sm"
    [matAutocomplete]="auto"
    [value]="currentValue ? currentValue : ''"
    [disabled]="initialValues.length === 0 || disabled || loading"
    (input)="onInputValueChange($event)"
  />
  <mat-icon
    *ngIf="currentValue && !disabled"
    (click)="clearInput()"
    class="autocomplete-action-icon clickable"
  >
    close
  </mat-icon>

  <mat-autocomplete
    class="autocomplete"
    #auto="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="optionSelected($event.option.value)"
  >
    <mat-option
      *ngFor="let value of filteredValues"
      class="opt font-sm"
      [value]="value"
    >
      {{ value }}
    </mat-option>
    <mat-option
      *ngIf="filteredValues?.length === 0"
      class="opt font-sm"
      disabled
    >
      No results found
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
