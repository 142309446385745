import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ROUTES } from '../../constants/routes/routes.constants';
import { HttpParamsFactory } from '../../factory/http-params/http-params.factory';
import { Article } from '../../interfaces/article/article.interface';
import { Combination } from '../../interfaces/combination/combination.interface';
import {
  CombinationFilter,
  CombinationFilters
} from '../../interfaces/combination/combination-filters/combination-filters.interface';
import { CombinationWithVariants } from '../../interfaces/combination/combination-with-variants/combination-with-variants.interface';

@Injectable()
export class HomeService {
  constructor(private readonly httpService: HttpService) {}

  getCombinationsWithFilters(
    options: CombinationFilter
  ): Observable<[Array<Combination>, CombinationFilters]> {
    const params = new HttpParamsFactory(options).returnParams();
    return forkJoin([
      this.httpService.request(ROUTES.COMBINATIONS).params(params).get(environment.backendUrl),
      this.httpService.request(ROUTES.FILTERS).params(params).get(environment.backendUrl)
    ]);
  }

  getCombinationWithVariants(id: string): Observable<CombinationWithVariants> {
    return this.httpService.request(`${ROUTES.COMBINATIONS}/${id}`).get(environment.backendUrl);
  }

  getCombinationArticles(variantId: string): Observable<Array<Article>> {
    return this.httpService
      .request(`${ROUTES.VARIANTS}/${variantId}/${ROUTES.ARTICLES}`)
      .get(environment.backendUrl);
  }

  getFilters(options?: CombinationFilter): Observable<CombinationFilters> {
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService.request(ROUTES.FILTERS).params(params).get(environment.backendUrl);
  }
}
