<app-small-header [loading]="loading$ | async"></app-small-header>

<cx-heart-beat [loading]="(loading$ | async)!"></cx-heart-beat>

<div
  *ngIf="(loading$ | async) === false && user?.admin"
  class="d-flex justify-content-center align-items-center mat-card-wrapper w-100"
>
  <div class="d-flex justify-content-center">
    <mat-card
      class="card card-admin clickable"
      [routerLink]="ROUTES.ADMINISTRATION"
    >
      <mat-card-content class="card-content">
        <span class="font-size-vw">Administration Role</span>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-end">
        <mat-icon>note</mat-icon>
      </mat-card-actions>
    </mat-card>

    <mat-card
      class="card clickable"
      [routerLink]="ROUTES.COMBINATIONS"
    >
      <mat-card-content class="card-content">
        <p class="font-size-vw">User Role</p>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-end">
        <mat-icon>assignment</mat-icon>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
