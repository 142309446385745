import { environment } from 'src/environments/environment';
import { environment as dev } from 'src/environments/environment.dev';
import { environment as prod } from 'src/environments/environment.prd';
import { environment as qas } from 'src/environments/environment.qas';

const mutateEnvironmentObject = (env: { [key: string]: any }) => {
  const currentEnv: { [key: string]: any } = environment;
  Object.keys(currentEnv).forEach((key) => (currentEnv[key] = env[key]));
};

export const setEnvironment = () => {
  if (window.location.href.includes('dev.bbraun.cloud')) {
    mutateEnvironmentObject(dev);
  } else if (window.location.href.includes('qas.bbraun.cloud')) {
    mutateEnvironmentObject(qas);
  } else if (window.location.href.includes('bbraun.cloud')) {
    mutateEnvironmentObject(prod);
  }
};
