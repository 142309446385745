import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
@Directive({
  selector: 'img[fallbackImage]'
})
export class DefaultImageDirective {
  @Input() fallbackImage: string | undefined;
  constructor(private ref: ElementRef) {}

  @HostListener('error')
  private loadDefault() {
    this.ref.nativeElement.src = this.fallbackImage || 'assets/images/no-image-icon.png';
  }
}
