import { createReducer, on } from '@ngrx/store';
import { Combination } from 'src/app/modules/shared/interfaces/combination/combination.interface';
import { CombinationFilters } from 'src/app/modules/shared/interfaces/combination/combination-filters/combination-filters.interface';

import * as adminCombinationsActions from './admin-combinations.actions';
import { loadFilters, loadTableDataAndFilters } from './admin-combinations.actions';

export const adminCombinationsFeatureKey = 'adminCombinations';

export interface AdminCombinationsState {
  filters: CombinationFilters;
  combinations: Array<Combination>;
  loading?: boolean;
}

export const initialState: AdminCombinationsState = {
  filters: {},
  combinations: [],
  loading: false
};

export const adminCombinationsReducer = createReducer<AdminCombinationsState>(
  initialState,
  on(
    loadTableDataAndFilters.loadTableAndFilters,
    loadFilters.loadFilters,
    (state: AdminCombinationsState) => ({
      ...state,
      loading: true
    })
  ),
  on(
    loadTableDataAndFilters.loadTableAndFiltersSuccess,
    (
      state: AdminCombinationsState,
      data: {
        combinations: Array<Combination>;
        filters: CombinationFilters;
      }
    ) => ({
      ...state,
      filters: data.filters,
      combinations: data.combinations,
      loading: false
    })
  ),
  on(
    loadFilters.loadFiltersSuccess,
    (state: AdminCombinationsState, data: { filters: CombinationFilters }) => ({
      ...state,
      filters: data.filters,
      loading: false
    })
  ),
  on(loadTableDataAndFilters.cleanupTableAndFilters, (state: AdminCombinationsState) => ({
    ...state,
    loading: false
  })),
  on(
    adminCombinationsActions.loadingAction,
    (state: AdminCombinationsState, data: { loading?: boolean }) => ({
      ...state,
      loading: data.loading
    })
  )
);
