import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Combination } from 'src/app/modules/shared/interfaces/combination/combination.interface';
import {
  CombinationFilter,
  CombinationFilters
} from 'src/app/modules/shared/interfaces/combination/combination-filters/combination-filters.interface';

export const loadTableDataAndFilters = createActionGroup({
  source: '[ ADMIN_COMBINATIONS ] Table and Filters',
  events: {
    'load table and filters': props<{ options: CombinationFilter }>(),
    'load table and filters success': props<{
      combinations: Array<Combination>;
      filters: CombinationFilters;
    }>(),
    'cleanup table and filters': emptyProps()
  }
});

export const loadFilters = createActionGroup({
  source: '[ ADMIN_COMBINATIONS ] Filters',
  events: {
    'load filters': props<{ options: CombinationFilter; listAll?: boolean }>(),
    'load filters success': props<{ filters: CombinationFilters }>()
  }
});

export const loadingAction = createAction(
  '[ ADMIN_COMBINATIONS ] LOADING',
  props<{ loading?: boolean }>()
);
export const deleteCombinationAction = createAction(
  '[ ADMIN_COMBINATIONS ] DELETE_COMBINATION',
  props<{ id: string; filters: CombinationFilter }>()
);
