<div class="d-flex flex-wrap justify-content-between w-100">
  <app-autocomplete-filter
    [placeholder]="TEXT.DRUG_CATEGORY"
    (selectedOption)="selectedDrugCategory($event)"
    (inputTerm)="inputTermDrugCategory($event)"
    [loading]="loading"
    [values]="filters.drugCategories ?? []"
    [value]="filter.drugCategory ?? ''"
  ></app-autocomplete-filter>

  <app-autocomplete-filter
    [placeholder]="TEXT.DRUG"
    (selectedOption)="selectedDrugs($event)"
    (inputTerm)="inputTermDrug($event)"
    [loading]="loading"
    [values]="filters.drugs ?? []"
    [value]="filter.drug ?? ''"
  ></app-autocomplete-filter>

  <app-autocomplete-filter
    [placeholder]="TEXT.CARRIER_SOLUTION"
    (selectedOption)="selectedCarrierSolutions($event)"
    (inputTerm)="inputTermCarrierSolutions($event)"
    [loading]="loading"
    [values]="filters.carrierSolutions ?? []"
    [value]="filter.carrierSolution ?? ''"
  ></app-autocomplete-filter>

  <app-autocomplete-filter
    [placeholder]="TEXT.PRODUCT"
    (selectedOption)="selectedProduct($event)"
    (inputTerm)="inputTermProduct($event)"
    [loading]="loading"
    [values]="filters.products ?? []"
    [value]="filter.product ?? ''"
  ></app-autocomplete-filter>
</div>

<div class="d-flex justify-content-end w-100">
  <button
    mat-stroked-button
    (click)="resetFilters()"
  >
    <mat-icon>settings_backup_restore</mat-icon>
    Reset Filters
  </button>
</div>
