import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { CombinationWithVariants } from 'src/app/modules/shared/interfaces/combination/combination-with-variants/combination-with-variants.interface';
import { HomeService } from 'src/app/modules/shared/service/home/home.service';

@Injectable()
export class CombinationResolver {
  constructor(private homeService: HomeService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CombinationWithVariants> {
    return this.homeService.getCombinationWithVariants(route.params['id']);
  }
}
