import { Injectable } from '@angular/core';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as applicationSelectors from './application.actions';

@Injectable()
export class ApplicationEffects {
  genericError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(applicationSelectors.genericErrorAction),
        map((payload: { message?: string }) => {
          const DEFAULT_MSG = `Oh, something went wrong! Sorry for the inconvenience. We got some issues with the server.
                            Please, try it again later.`;
          this.notificationService.error(payload.message ? payload.message : DEFAULT_MSG);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: CxSnackbarService
  ) {}
}
