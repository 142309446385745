import { createAction, createActionGroup, props } from '@ngrx/store';
import { CarrierSolution } from 'src/app/modules/shared/interfaces/entities/carrier-solution.interface';
import { Drug } from 'src/app/modules/shared/interfaces/entities/drug.interface';
import { DrugCategory } from 'src/app/modules/shared/interfaces/entities/drug-category.interface';
import { Product } from 'src/app/modules/shared/interfaces/entities/product.interface';

export const drugCategories = createActionGroup({
  source: '[ ADMIN ENTITIES ] DRUG_CATEGORIES',
  events: {
    load: props<{ searchInput?: string }>(),
    'load success': props<{ drugCategories: Array<DrugCategory> }>(),
    delete: props<{ id: number; searchInput: string; name: string }>()
  }
});

export const drugs = createActionGroup({
  source: '[ ADMIN ENTITIES ] DRUGS',
  events: {
    load: props<{ searchInput?: string }>(),
    'load success': props<{ drugs: Array<Drug> }>(),
    delete: props<{ id: number; searchInput: string; name: string }>()
  }
});

export const carrierSolutions = createActionGroup({
  source: '[ ADMIN ENTITIES ] CARRIER SOLUTIONS',
  events: {
    load: props<{ searchInput?: string }>(),
    'load success': props<{ carrierSolutions: Array<CarrierSolution> }>(),
    delete: props<{ id: number; searchInput: string; name: string }>()
  }
});

export const products = createActionGroup({
  source: '[ ADMIN ENTITIES ] PRODUCTS',
  events: {
    load: props<{ searchInput?: string }>(),
    'load success': props<{ products: Array<Product> }>(),
    delete: props<{ id: number; searchInput: string; name: string }>()
  }
});

export const setTab = createAction('[ ADMIN ENTITIES ] SET_TAB', props<{ tabIndex: number }>());
export const cleanUpTables = createAction('[ ADMIN ENTITIES ] CLEANUP_TABLES');
