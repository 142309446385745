import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-filter',
  templateUrl: 'autocomplete-filter.component.html',
  styleUrls: ['autocomplete-filter.component.sass']
})
export class AutocompleteFilterComponent implements OnInit, OnDestroy {
  @Input()
  set values(data: Array<string>) {
    if (data) {
      this.initialValues = data;
      this.filteredValues = data.map((x) => x);
    }
  }

  // Placeholder text
  @Input() placeholder!: string;

  @Input()
  set value(data: string) {
    if (data) {
      this.currentValue = data;
    }
  }

  @Input() disabled!: boolean;

  @Input() loading!: boolean;

  // EventEmitter to emit updated inputTerm
  @Output() inputTerm = new EventEmitter<string>();

  @Output() selectedOption = new EventEmitter<string>();

  // Subject to handle input term changes
  inputTermChanged = new Subject<string>();

  initialValues!: Array<string>;

  filteredValues!: Array<string>;

  // Current input value
  currentValue!: string;

  @ViewChild('input') private input!: ElementRef;

  private onDestroy = new Subject<void>();

  ngOnInit() {
    this.subscribeToTermChange();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  clearInput(emit = true) {
    this.input.nativeElement.value = '';
    this.currentValue = '';
    if (emit) {
      this.selectedOption.emit('');
    }
  }

  optionSelected(value: string) {
    this.currentValue = value;
    this.selectedOption.emit(value);
  }

  onInputValueChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.inputTermChanged.next(inputElement.value);
  }

  private subscribeToTermChange() {
    this.inputTermChanged?.pipe(takeUntil(this.onDestroy)).subscribe((inputTerm: string) => {
      this.currentValue = inputTerm;
      this.inputTerm.emit(this.currentValue);
      this.filteredValues = this.initialValues.filter((x) =>
        x.toLocaleLowerCase().includes(inputTerm.toLocaleLowerCase())
      );
    });
  }
}
