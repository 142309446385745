import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';
import { CarrierSolution } from 'src/app/modules/shared/interfaces/entities/carrier-solution.interface';
import { Drug } from 'src/app/modules/shared/interfaces/entities/drug.interface';
import { DrugCategory } from 'src/app/modules/shared/interfaces/entities/drug-category.interface';
import { Product } from 'src/app/modules/shared/interfaces/entities/product.interface';

import { environment } from '../../../../../environments/environment';
import { HttpParamsFactory } from '../../factory/http-params/http-params.factory';

@Injectable()
export class EntitiesService {
  constructor(private readonly httpService: HttpService) {}

  getDrugsCategories(name?: string): Observable<Array<DrugCategory>> {
    const params = new HttpParamsFactory({ name }).returnParams();
    return this.httpService.request('drugCategories').params(params).get(environment.backendUrl);
  }

  getDrugCategoryById(id: string): Observable<DrugCategory> {
    return this.httpService.request('drugCategories/' + id).get(environment.backendUrl);
  }

  putDrugsCategory(body: DrugCategory): Observable<any> {
    return this.httpService
      .request(`drugCategories/${body.categoryId}`)
      .body(body)
      .put(environment.backendUrl);
  }

  postDrugsCategory(body: DrugCategory): Observable<any> {
    return this.httpService.request('drugCategories').body(body).post(environment.backendUrl);
  }

  deleteDrugCategoryById(id: number): Observable<any> {
    return this.httpService.request('drugCategories/' + id).delete(environment.backendUrl);
  }

  getDrugs(name?: string): Observable<Array<Drug>> {
    const params = new HttpParamsFactory({ name }).returnParams();
    return this.httpService.request('drugs').params(params).get(environment.backendUrl);
  }

  getDrugById(id: string): Observable<Drug> {
    return this.httpService.request('drugs/' + id).get(environment.backendUrl);
  }

  postDrug(body: Drug): Observable<any> {
    return this.httpService.request('drugs').body(body).post(environment.backendUrl);
  }

  putDrug(body: Drug): Observable<any> {
    return this.httpService.request(`drugs/${body.drugId}`).body(body).put(environment.backendUrl);
  }

  deleteDrugById(id: number): Observable<any> {
    return this.httpService.request('drugs/' + id).delete(environment.backendUrl);
  }

  getCarrierSolutions(name?: string): Observable<Array<CarrierSolution>> {
    const params = new HttpParamsFactory({ name }).returnParams();
    return this.httpService.request('carrierSolutions').params(params).get(environment.backendUrl);
  }

  getCarrierSolutionById(id: string): Observable<CarrierSolution> {
    return this.httpService.request('carrierSolutions/' + id).get(environment.backendUrl);
  }

  deleteCarrierSolutionById(id: number): Observable<any> {
    return this.httpService.request('carrierSolutions/' + id).delete(environment.backendUrl);
  }

  postCarrierSolution(body: CarrierSolution): Observable<any> {
    return this.httpService.request('carrierSolutions').body(body).post(environment.backendUrl);
  }

  putCarrierSolution(body: CarrierSolution): Observable<any> {
    return this.httpService
      .request(`carrierSolutions/${body.solutionId}`)
      .body(body)
      .put(environment.backendUrl);
  }

  getProducts(name?: string): Observable<Array<Product>> {
    const params = new HttpParamsFactory({ name }).returnParams();
    return this.httpService.request('products').params(params).get(environment.backendUrl);
  }

  deleteProductById(id: number): Observable<any> {
    return this.httpService.request('products/' + id).delete(environment.backendUrl);
  }

  getProductById(id: string): Observable<Product> {
    return this.httpService.request('products/' + id).get(environment.backendUrl);
  }

  postProduct(body: Product): Observable<Product> {
    return this.httpService.request('products').body(body).post(environment.backendUrl);
  }

  putProduct(body: Product): Observable<Product> {
    return this.httpService
      .request(`products/${body.productId}`)
      .body(body)
      .put(environment.backendUrl);
  }
}
