import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { environment } from '@environment/environment';
import { HTTP_STATUS_CODE } from '@shared/constants/http-status-codes/http-status-codes.constant';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { JIRA_REGISTRAION_TICKET } from '@shared/constants/url/url.constants';
import { CustomHttpError } from '@shared/interfaces/custom-http-error/custom-http-error.interface';
import { User } from '@shared/interfaces/user/user.interface';
import { OAuthService } from '@shared/service/auth/o-auth.service';
import { UserService } from '@shared/service/user/user.service';
import { UserFacade } from '@store/user/user.facade';
import { concatMap, filter, take } from 'rxjs';

declare function ineum(command: string, ...data: Array<any>): void;
declare function instanaMonitoring(reportingUrl: string, apiKey: string): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public readonly userFacade: UserFacade,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackBarService: CxSnackbarService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly oAuthService: OAuthService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.oAuthService
      .configureAuth()
      .pipe(
        filter((success) => success),
        take(1),
        concatMap(() => this.userService.getUser().pipe(take(1)))
      )
      .subscribe({
        next: (user: User) => {
          this.userDataFetchedSuccessfully(user);
        },
        error: (err: CustomHttpError) => {
          this.useDataFetchedWithError(err);
        }
      });
  }

  // instana config
  ngAfterViewInit(): void {
    this.loadInstanaMonitoringScript();
    this.loadInstanaMonitoringRouterEvents();
    this.snackBarService.setViewContainerRef(this.viewContainerRef);
  }

  private loadInstanaMonitoringScript(): void {
    if (environment.trackingUi.enable) {
      instanaMonitoring(environment.trackingUi.reportingUrl, environment.trackingUi.apiKey);
    }
  }

  private loadInstanaMonitoringRouterEvents(): void {
    if (environment.trackingUi.enable) {
      this.router.events.subscribe((event) => {
        // tslint:disable-next-line:no-string-literal
        const componentName =
          this.activatedRoute.component !== null
            ? this.activatedRoute.component['name']
            : 'default';

        if (event instanceof NavigationStart) {
          ineum('page', componentName);
          ineum('startSpaPageTransition');
        } else if (event instanceof NavigationEnd) {
          ineum('meta', 'component', componentName);
          ineum('endSpaPageTransition', { status: 'completed' });
        } else if (event instanceof NavigationError) {
          ineum('endSpaPageTransition', {
            status: 'error',
            url: window.location.href,
            explanation: event.error.toString()
          });
        } else if (event instanceof NavigationCancel) {
          ineum('endSpaPageTransition', {
            status: 'aborted',
            url: window.location.href,
            explanation: event.reason
          });
        }
      });
    }
  }

  private userDataFetchedSuccessfully(user: User): void {
    if (user && (user.admin || user.user)) {
      this.userFacade.userDataLoaded(user);
      this.router.initialNavigation();
    } else {
      window.location.href = JIRA_REGISTRAION_TICKET;
    }
  }

  private useDataFetchedWithError(error: CustomHttpError): void {
    this.userFacade.userDataNotLoaded();
    if (error.status >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR) {
      this.router?.navigateByUrl('/' + ROUTES.SERVER_ERROR);
    }
  }
}
